<template>
    <div class="d_flex_col center main" id="intro">
        <h1 v-scroll-reveal.reset="{ delay: 300 }">
            <span class="upper_text">Hello World!</span>
        </h1>

        <h1 v-scroll-reveal.reset="{ delay: 700 }">
            <span class="lower_text">I am a DevOps Engineer</span>
        </h1>

        <LinkButton :to="'#about'" :text="'About Me'"></LinkButton>
    </div>
</template>

<script>
import { Intersection_observer } from '@/utils/observer'
const LinkButton = () => import('@/components/LinkButton')
export default {
    components: {
        LinkButton
    },
    mounted() {
        Intersection_observer(this.handleInteract, this.$el)
    },
    methods: {
        async handleInteract(entries, observer) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.$store.dispatch('SET_CURRENT_SECTION', 'intro')
                }
            })
        }
    }
}
</script>

<style lang="scss">
.main {
    min-height: 100vh;
    // max-width: 100vw;
    text-align: center;
    h1 {
        position: relative;
        width: 100%;
        z-index: -2;
        .upper_text {
            position: absolute;
            text-align: center;
            left: 0;
            right: 0;

            color: $tertiary;
            animation: FadeUp 0.8s ease-in-out 0.5s 1 normal both;
        }
        .lower_text {
            position: absolute;
            left: 0;
            right: 0;
            color: $secondary;
            animation: FadeUp 0.8s ease-in-out 0.8s 1 normal both;
        }
    }
}

@media screen and (max-width: 500px) {
    h1 {
        font-size: 24px;
    }
}
</style>
